import {
  Avatar as Base,
  AvatarProps as BaseProps,
  Tooltip,
} from "@mui/material";
import {
  amber,
  brown,
  green,
  grey,
  lightBlue,
  teal,
} from "@mui/material/colors";
import getCurrentTrainingStep, {
  TrainingLabel,
} from "src/pages/DashboardPage/getCurrentTrainingStep";
import { UserAccount } from "src/types/User";
import { getFullName } from "src/utils";
import toInitials from "src/utils/toInitials";

export type UserAvatarProps = BaseProps & {
  user: Pick<
    UserAccount,
    | "firstName"
    | "lastName"
    | "uid"
    | "applicationStatus"
    | "enrollmentStatus"
    | "employmentStatus"
    | "onboardingStatus"
  >;
  tooltip?: boolean;
};

function onboardingStatusToColor(label: TrainingLabel) {
  switch (label) {
    case "Admissions":
      return brown;
    case "Online Training":
      return lightBlue;
    case "Skills Training":
      return amber;
    case "Job Search":
      return teal;
    case "On the Job":
      return green;
    default:
      return grey;
  }
}

export default function UserAvatar({
  user,
  children,
  tooltip,
  ...rest
}: UserAvatarProps) {
  // pick a color from the palette if there's no image
  const label = getCurrentTrainingStep(user);
  const hue = onboardingStatusToColor(label);
  const colorProps = hue
    ? { color: hue[900], backgroundColor: hue[100] }
    : undefined;
  const sxProps = { ...rest.sx, ...colorProps };

  return (
    <Tooltip title={tooltip && getFullName(user)} placement="top" arrow>
      <Base {...rest} sx={sxProps}>
        {children || toInitials(user)}
      </Base>
    </Tooltip>
  );
}
