import { addDays } from "date-fns";
import { GetRequiredExamsForStudyPlanQuery } from "./GetRequiredExamsForStudyPlan.generated";
import { GetUnitsForStudyPlanQuery } from "./GetUnitsForStudyPlan.generated";

type StudyPlanWeek = {
  weekNumber: number;
  lessonIds: string[];
  examIds: string[];
  totalDuration: number; // in minutes
  targetEndDate: string; // ISO formatted string for the target end date
};

export type StudyPlanData = {
  totalVideoDuration: number;
  totalExamDuration: number;
  totalWeeks: number;
  weeklyPlan: StudyPlanByWeek;
  startDate: string;
  weeklyCommitmentHours: number;
};

export type StudyPlanByWeek = {
  [weekNumber: number]: StudyPlanWeek;
};

export default function generateStudyPlan(
  unitsData: GetUnitsForStudyPlanQuery["allUnits"],
  examsData: GetRequiredExamsForStudyPlanQuery["allQuizzes"],
  weeklyCommitmentHours: number,
  startDate: Date
): StudyPlanData {
  const units = unitsData || [];
  const exams = examsData || [];

  const totalVideoDuration = units.reduce(
    (total, unit) =>
      total +
      unit.resources.reduce((sum, resource) => sum + resource.duration, 0),
    0
  ); // in minutes

  const totalExamDuration = exams.reduce(
    (total, exam) => total + exam.questions.length,
    0
  ); // Assuming exam duration is equal to the number of questions (1 minute per question)

  const weeklyCommitmentMinutes = weeklyCommitmentHours * 60;
  const weeklyPlan: StudyPlanByWeek = {};

  let remainingMinutes = weeklyCommitmentMinutes;
  let currentWeekNumber = 0; // Start at week 0
  let currentWeekTargetEndDate = addDays(new Date(startDate), 7); // Start with the provided start date

  let currentWeek: StudyPlanWeek = {
    weekNumber: currentWeekNumber,
    lessonIds: [],
    examIds: [],
    totalDuration: 0,
    targetEndDate: new Date(currentWeekTargetEndDate).toISOString(), // Initial target end date
  };

  // Helper function to push the current week and start a new one
  const addCurrentWeekAndReset = () => {
    weeklyPlan[currentWeekNumber] = currentWeek;
    currentWeekNumber += 1;
    currentWeekTargetEndDate = addDays(currentWeekTargetEndDate, 7); // Move to the next week
    currentWeek = {
      weekNumber: currentWeekNumber,
      lessonIds: [],
      examIds: [],
      totalDuration: 0,
      targetEndDate: new Date(currentWeekTargetEndDate).toISOString(),
    };
    remainingMinutes = weeklyCommitmentMinutes;
  };

  // Check each lesson's duration
  units.forEach((unit) => {
    unit.resources.forEach((resource) => {
      const resourceDuration = resource.duration;

      // Check if the lesson duration exceeds the weekly availability
      if (resourceDuration > weeklyCommitmentMinutes) {
        throw new Error(
          `The lesson "${resource.id}" requires more time (${resourceDuration} minutes) than your available weekly study time (${weeklyCommitmentMinutes} minutes). Your availability to study is too low.`
        );
      }

      // Check if this resource fits entirely into the remaining time for the current week
      if (resourceDuration > remainingMinutes) {
        // Push the current week to the plan and start a new week
        addCurrentWeekAndReset();
      }

      // Now we know that resource fits into the current week, so we can add it
      currentWeek.lessonIds.push(resource.id);
      currentWeek.totalDuration += resourceDuration;
      remainingMinutes -= resourceDuration;
    });
  });

  // Check each exam's duration
  exams.forEach((exam) => {
    const examDuration = exam.questions.length;

    // Check if the exam duration exceeds the weekly availability
    if (examDuration > weeklyCommitmentMinutes) {
      throw new Error(
        `The exam "${exam.id}" requires more time (${examDuration} minutes) than your available weekly study time (${weeklyCommitmentMinutes} minutes). Your availability to study is too low.`
      );
    }

    // Check if this exam fits entirely into the remaining time for the current week
    if (examDuration > remainingMinutes) {
      // Push the current week to the plan and start a new week
      addCurrentWeekAndReset();
    }

    // Now we know that exam fits into the current week, so we can add it
    currentWeek.examIds.push(exam.id);
    currentWeek.totalDuration += examDuration;
    remainingMinutes -= examDuration;
  });

  // Ensure the last week is added if it contains any content
  if (currentWeek.lessonIds.length > 0 || currentWeek.examIds.length > 0) {
    weeklyPlan[currentWeekNumber] = currentWeek;
  }

  return {
    totalVideoDuration,
    totalExamDuration,
    totalWeeks: currentWeekNumber + 1, // Since weeks start at 0, we add 1 to get total weeks
    weeklyPlan,
    startDate: startDate.toISOString(),
    weeklyCommitmentHours,
  };
}
