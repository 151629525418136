import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Header, PaddedLayout } from "src/components/Layout";
import Template from "src/components/Layout/Template";
import { useCurrentUser } from "src/SessionBoundary";
import { UserOnboardingStatus } from "src/types/User";
import { CLP_ID } from "src/utils/constants";
import GradesTab from "../ClientScreen/GradesTab";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import AdminParticipantChat from "./AdminParticipantChatTab/AdminParticipantChatTab";
import DashboardCohortAnnouncements from "./DashboardCohortAnnouncements";
import DashboardTabs, { DashboardTab } from "./DashboardTabs";
import DashboardTrainingProgressCard from "./DashboardTrainingProgressCard";
import ResourceTab from "./ProfileScreen";
import PWASnackbar from "./PWASnackbar";
import RangeTab from "./RangeTab/RangeTab";
import SavedTab from "./SavedTab";
import TrainingProgressStepper from "./TrainingProgressStepper";

// ID of  CLP is currently hardcoded
export const DashboardScreen = () => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({
    tab: "home",
  });

  const [tab, setTab] = useState<DashboardTab>(
    (searchParams.get("tab") as DashboardTab) || "home"
  );

  const handleTabChange = (value: DashboardTab) => {
    setTab(value);
    setSearchParams((prev) => {
      prev.set("tab", value);
      return prev;
    });
  };

  useEffect(() => {
    if (
      user.onboardingStatus === UserOnboardingStatus.RANGE ||
      user.enrollmentStatus === "skills_training" ||
      user.enrollmentStatus === "completed_skills_training"
    )
      setTab("range");
  }, [user.enrollmentStatus, user.onboardingStatus]);

  const navigateToCourse = () => navigate(`/courses/${CLP_ID}`);

  return (
    <Template>
      <PWASnackbar />
      <Header title="CDL Course" section={`Welcome back, ${user.firstName}!`}>
        <DashboardTabs onChange={handleTabChange} value={tab} />
      </Header>
      {user.cohortId && user.organizationId && (
        <PaddedLayout>
          <DashboardCohortAnnouncements
            cohort={{ uid: user.cohortId, organizationId: user.organizationId }}
          />
        </PaddedLayout>
      )}
      {tab === "home" && (
        <Box>
          <TrainingProgressStepper user={user} />
          <PaddedLayout>
            <DashboardTrainingProgressCard
              client={user}
              handleClick={navigateToCourse}
            />
          </PaddedLayout>
          <PaddedLayout>
            <Typography fontWeight="medium" mb={2}>
              {t("Your Upcoming Milestones")}
            </Typography>
            <StudentPlanTab
              client={user}
              minified
              cutoffDays={14}
              onReviewSuccessPlan={() => setTab("success_plan")}
            />
          </PaddedLayout>
        </Box>
      )}
      {tab === "resources" && <ResourceTab />}
      {tab === "grades" && <GradesTab client={user} />}
      {tab === "saved" && <SavedTab />}
      {tab === "chat" && (
        <Box>
          <Box margin="auto" maxWidth={800}>
            <Card>
              <CardContent>
                <AdminParticipantChat client={user} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
      {tab === "success_plan" && <StudentPlanTab client={user} />}
      {tab === "range" && (
        <PaddedLayout>
          <RangeTab />
        </PaddedLayout>
      )}
    </Template>
  );
};

export default DashboardScreen;
