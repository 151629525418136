import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/WhiteFullLogo.svg";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import { useAuth } from "src/contexts/AuthContext";
import fetchCohort from "src/firebase/fetchCohort";
import fetchOrganization from "src/firebase/fetchOrganization";
import { SIGNUP_INFORMATION_SEED } from "src/seed";
import { Organization } from "src/types";
import { Cohort } from "src/types/Cohort";
import formatNumberToE164 from "src/utils/formatNumberToE164";
import useErrorHandler from "src/utils/useErrorHandler";
import SliderExplanationScreen from "../SliderExplanationScreen";
import CredentialSignupForm, {
  CredentialsStepData,
} from "./CredentialSignupForm";
import LocationSignupForm, { LocationStepData } from "./LocationSignupForm";
import ProfileSignupForm, { ProfileStepData } from "./ProfileSignupForm";
import RegisterLayout from "./RegisterLayout";

export type SignupFormData = CredentialsStepData &
  ProfileStepData &
  LocationStepData;

type FormStep = "credentials" | "profile" | "location";

const steps = [
  {
    imageUrl:
      "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/GabeConstance.png",
    title: "Welcome to Emerge Career",
    titleProps: { fontWeight: "bold" },
    body: "We are a hybrid job training program that prepares you for a new beginning and a high-paying career",
  },
  {
    imageUrl:
      "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/ZoBlue.png",
    title: "We Care",
    titleProps: { fontWeight: "bold" },
    body: "Our program covers all training costs and offers 1-on-1 support to help you succeed inside and outside of training",
  },
  {
    imageUrl:
      "https://f004.backblazeb2.com/file/emerge-courses/thumbnails/product/RobertoGreen.png",
    title: "Honesty is the Best Policy",
    titleProps: { fontWeight: "bold" },
    body: "We will be upfront with you, and we expect the same in return.",
  },
];

export default function SignupScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("credentials");
  const { registerStudent } = useAuth();
  const handleError = useErrorHandler();
  const [searchParams] = useSearchParams();
  const cohortId = searchParams.get("cohortId");
  const organizationId = searchParams.get("organizationId");
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignupFormData>(
    process.env.NODE_ENV === "development"
      ? SIGNUP_INFORMATION_SEED
      : {
          email: "",
          password: "",
          passwordConfirmation: "",
          firstName: "",
          lastName: "",
          dateOfBirth: undefined,
          termsOfService: false,
          state: undefined,
          city: "",
          phone: "",
          referralSource: undefined,
          doesNotHaveEmail: false,
        }
  );
  const [cohort, setCohort] = useState<Cohort>();
  const [organization, setOrganization] = useState<Organization>();
  const { clients } = useAppContext();

  useEffect(() => {
    if (!organizationId) return;

    fetchOrganization(organizationId, clients)
      .then(setOrganization)
      .catch(handleError);

    if (!cohortId) return;

    fetchCohort(organizationId, cohortId, clients)
      .then(setCohort)
      .catch(handleError);
  }, [cohortId, organizationId, clients, handleError]);

  const onSubmit = async ({
    email,
    password,
    passwordConfirmation,
    dateOfBirth,
    phone,
    ...input
  }: SignupFormData) => {
    if (!dateOfBirth) return;
    if (!input.state) throw new Error("Missing state");
    if (!input.referralSource) throw new Error("Missing referreral source");

    try {
      const success = await registerStudent({
        ...input,
        email: email.trim().toLowerCase(),
        // TODO: remove this fallback once we validate in production the e164 format
        phone: formatNumberToE164(phone) || phone,
        password: password.trim(),
        dateOfBirth: dateOfBirth.toISOString(),
        state: input.state!,
        cohortId: cohort?.uid,
        organizationId: organization?.uid,
      });
      if (success) setFinished(true);
    } catch (err) {
      handleError(err);
    }
  };

  if (!finished) {
    return (
      <RegisterLayout corner={<Logo width={300} />} organization={organization}>
        <ScreenTitle>{t("Ready to Emerge?")}</ScreenTitle>
        {step === "credentials" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                {t(
                  "We assist you with obtaining scholarships for high-paying vocations. Congrats on taking the first step towards the next step in your life's journey."
                )}
              </Typography>
            </Box>
            <CredentialSignupForm
              defaultValues={formData}
              onSubmit={(data) => {
                setFormData({ ...formData, ...data });
                setStep("profile");
              }}
            />
          </>
        )}
        {step === "profile" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                <Trans
                  t={t}
                  defaults="Got it, <bold>{{first_name}}</bold>. Now let's get a bit more info to finish creating your account. Please double check this information matches your legal ID, as this is what will be used by security officials to verify your identity."
                  values={{ first_name: formData.firstName }}
                  components={{ bold: <b /> }}
                />
              </Typography>
            </Box>
            <ProfileSignupForm
              defaultValues={formData}
              onBack={() => setStep("credentials")}
              onSubmit={async (data) => {
                setFormData({ ...formData, ...data });
                setStep("location");
              }}
            />
          </>
        )}
        {step === "location" && (
          <>
            <Box marginTop={2} marginBottom={6}>
              <Typography variant="body1">
                <Trans
                  t={t}
                  defaults="<bold>{{firstName}}</bold>, now we just need to know your location to identify the right programs for you."
                  values={{ firstName: formData.firstName }}
                  components={{ bold: <b /> }}
                />
              </Typography>
            </Box>
            <LocationSignupForm
              defaultValues={formData}
              onBack={() => setStep("profile")}
              onSubmit={async (data) => {
                setFormData({ ...formData, ...data });
                await onSubmit({ ...formData, ...data });
              }}
            />
          </>
        )}
      </RegisterLayout>
    );
  }

  return (
    <SliderExplanationScreen
      finishButtonProps={{
        onFinish: () => navigate("/onboarding/survey"),
      }}
      steps={steps}
    />
  );
}
