import { Check, EditOutlined, Warning } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Avatar from "src/components/Avatar";
import { StudentReference } from "src/types/StudentReference";
import { getFullName } from "src/utils";
import formatDateString from "src/utils/formatDateString";
import getInitials from "src/utils/getInitials";
import CopiableEmail from "../ClientScreen/CopiableEmail";
import CopiablePhone from "../ClientScreen/CopiablePhone";
import ReferenceStatusChip from "../ClientScreen/ReferenceStatusChip";

type Props = {
  reference: StudentReference;
  onEdit: () => void;
};

function ReferenceCard({ reference, onEdit }: Props) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <Avatar>{getInitials(getFullName(reference))}</Avatar>
              <Typography>{getFullName(reference)}</Typography>
              <Typography color="text.secondary">
                ({reference.relationship})
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" gap={3}>
            <Stack direction="row" gap={2} alignItems="center">
              {reference.verifiedAt ? (
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Check color="success" />
                  <Typography variant="body2" color="text.secondary">
                    {t("Verified")}
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Warning color="warning" />
                  <Typography variant="body2" color="text.secondary">
                    {t("Not Verified")}
                  </Typography>
                </Stack>
              )}

              {reference.subscribedAt && (
                <Typography>
                  {t("Receiving updates since {{date}}", {
                    date: t("since {{ date }} ", {
                      date: formatDateString(reference.subscribedAt, "date"),
                    }),
                  })}
                </Typography>
              )}

              {reference.status && (
                <ReferenceStatusChip status={reference.status} />
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction="row" gap={2}>
          <CopiableEmail email={reference.email} />
          <CopiablePhone phone={reference.phone} />
        </Stack>
        <Button
          startIcon={<EditOutlined />}
          onClick={onEdit}
          sx={{ marginLeft: "auto" }}
        >
          {t("Edit")}
        </Button>
      </CardActions>
    </Card>
  );
}

export default ReferenceCard;
