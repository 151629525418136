import { Create } from "@mui/icons-material";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import DateInput from "src/components/Input/DateInput";
import TextInput from "src/components/Input/TextInput";
import { ScreenTitle } from "src/components/Typography";
import MilestoneType from "src/types/MilestoneType.enum";
import { StudentPlanMilestone } from "src/types/StudentPlan";

type Props = {
  onSubmit: (data: StudentPlanMilestoneFormData) => void;
  defaultValues?: Partial<Omit<StudentPlanMilestone, "date">> & { date: Date };
  availableMilestoneTypes: MilestoneTemplate[];
};

export type StudentPlanMilestoneFormData = Pick<
  StudentPlanMilestone,
  "title" | "type"
> & { date: Date };

type MilestoneTemplate = {
  name: string;
  description: string;
  type: MilestoneType;
  link?: string;
};

export const REQUIRED_MILESTONES: MilestoneTemplate[] = [
  {
    name: "Take the DOT Physical Exam",
    description: "You must take the DOT physical exam to pass the permit",
    type: MilestoneType.MEDICAL_EXAM,
  },
  {
    name: "Complete All Practice Exams",
    description:
      "This is the date you are aiming to finish the theory training",
    type: MilestoneType.THEORY_TRAINING_COMPLETION,
  },
  {
    name: "Obtain the Learner's Permit",
    description: "The date you will attempt to take the learner's permit exam",
    type: MilestoneType.THEORY_EXAM,
  },
  {
    name: "Pass Drug Test",
    description:
      "You must pass a drug test to start the driving training. Note that marijuana is strictly prohibited.",
    type: MilestoneType.DRUG_TEST,
  },
  {
    name: "Start Driving Training",
    description: "The date you will start the driving training",
    type: MilestoneType.SKILLS_TRAINING_START,
  },
  {
    name: "End Driving Training",
    description: "The end date will depend on the school you attend.",
    type: MilestoneType.SKILLS_TRAINING_END,
  },
  {
    name: "CDL Exam",
    description: "The date you aim to take the final CDL exam.",
    type: MilestoneType.LICENSE_EXAM,
  },
];

const StudentPlanMilestoneForm = ({
  onSubmit,
  defaultValues,
  availableMilestoneTypes,
}: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm<StudentPlanMilestoneFormData>({
    mode: "onTouched",
    defaultValues: defaultValues || {},
  });
  const { t } = useTranslation();
  const theme = useTheme();

  const milestoneType = watch("type");

  useEffect(() => {
    if (!!milestoneType && milestoneType !== MilestoneType.OTHER) {
      const defaultTitle = REQUIRED_MILESTONES.find(
        (milestone) => milestone.type === milestoneType
      )?.name;
      if (!defaultTitle)
        throw new Error("Could not find default title for milestone type");
      setValue("title", defaultTitle);
    }
  }, [milestoneType, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={theme.spacing(2)}>
        <Stack spacing={theme.spacing(1)}>
          <ScreenTitle>{t("Create a milestone")}</ScreenTitle>
          <Typography variant="body1">
            {t("A milestone is an achievement that is relevant to the course.")}
          </Typography>
        </Stack>
        <SelectInput
          name="type"
          label="Type"
          control={control}
          items={[
            ...availableMilestoneTypes,
            {
              name: "Other",
              description: "Custom milestones.",
              type: MilestoneType.OTHER,
            },
          ].map((template) => ({
            value: template.type,
            name: template.name,
          }))}
          rules={{
            required: { value: true, message: t("This field is required") },
          }}
        />
        {milestoneType === MilestoneType.OTHER && (
          <TextInput
            control={control}
            name="title"
            label={t("Title")}
            rules={{
              required: { value: true, message: t("Title is required") },
            }}
          />
        )}
        <DateInput
          control={control}
          name="date"
          label={t("Date")}
          rules={{
            required: { value: true, message: t("Date is required") },
          }}
        />
        <SubmitButton
          loading={isSubmitting}
          size="large"
          startIcon={<Create />}
        >
          {t("Create")}
        </SubmitButton>
      </Stack>
    </form>
  );
};

export default StudentPlanMilestoneForm;
