import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardContent,
  MobileStepper,
  Modal,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalContainer from "src/components/ModalContainer";

export type SliderExplanationStepProp = {
  imageUrl: string;
  title: string;
  titleProps?: SxProps;
  body: ReactNode;
};

type FinishButtonProps = {
  onFinish?: () => void;
  label?: string;
};

type Props = {
  steps: SliderExplanationStepProp[];
  finishButtonProps?: FinishButtonProps;
};

export default function SliderExplanationScreen({
  steps,
  finishButtonProps = {},
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // State to control fade-in
  const maxSteps = steps.length;

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setIsImageLoaded(false); // Reset image load state when moving to the next step
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    setIsImageLoaded(false); // Reset image load state when going back
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true); // Trigger fade-in when the image is loaded
  };

  const { onFinish, label } = finishButtonProps;

  return (
    <Modal
      open
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContainer>
        <Card>
          <CardContent>
            <Stack spacing={6} alignItems="center">
              {steps[currentStep].imageUrl && (
                <img
                  src={steps[currentStep].imageUrl}
                  alt={`${steps[currentStep].title} illustration`}
                  height={150}
                  width={200}
                  style={{
                    objectFit: "cover",
                    borderRadius: 8,
                    opacity: isImageLoaded ? 1 : 0, // Control opacity for fade-in
                    transition: "opacity 1s ease-in", // Fade-in effect
                  }}
                  onLoad={handleImageLoad} // Image load event
                />
              )}
              <Stack spacing={2} alignItems="center">
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    ...(steps[currentStep].titleProps || {}),
                  }}
                  id="modal-title"
                >
                  {steps[currentStep].title}
                </Typography>
                <Box
                  sx={{ textAlign: "center", marginTop: 2, maxWidth: 600 }}
                  id="modal-description"
                >
                  {steps[currentStep].body}
                </Box>
              </Stack>
              <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={currentStep}
                sx={{ width: "100%", maxWidth: 600 }}
                nextButton={
                  <Button
                    size="large"
                    variant={
                      currentStep === maxSteps - 1 ? "contained" : "text"
                    }
                    onClick={
                      currentStep === maxSteps - 1
                        ? onFinish || (() => navigate("/"))
                        : handleNext
                    }
                  >
                    {currentStep === maxSteps - 1
                      ? label || t("Finish")
                      : t("Next")}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="large"
                    onClick={handleBack}
                    disabled={currentStep === 0}
                  >
                    <KeyboardArrowLeft />
                    {t("Back")}
                  </Button>
                }
              />
            </Stack>
          </CardContent>
        </Card>
      </ModalContainer>
    </Modal>
  );
}
