import { format, isValid } from "date-fns";

export default function formatDateString(
  dateString: string,
  type: "date" | "time" | "datetime" | "dateyear"
): string {
  if (!isValid(dateString)) return "";
  const date = new Date(dateString);
  if (type === "date") return format(date, "MMM d, yyyy");
  if (type === "time") return format(date, "HH:mm");
  if (type === "datetime") return format(date, "MM/dd/yy HH:mm");
  if (type === "dateyear") return format(date, "yyyy-MM-dd");
  return dateString;
}
