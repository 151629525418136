import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Button, Typography } from "@mui/material";
import { addDays, format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/contexts/AppContext";
import resetTrainingMilestones from "src/firebase/resetTrainingMilestones";
import { useCurrentUser } from "src/SessionBoundary";
import calculateMilestoneDates from "src/utils/calculateMilestoneDates";
import useErrorHandler from "src/utils/useErrorHandler";

type Props = {
  onContinue: () => void;
};

export default function SuccessPlanReview({ onContinue }: Props) {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const { clients } = useAppContext();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();

  const milestoneDates = calculateMilestoneDates(user);

  const handleFinish = async () => {
    await resetTrainingMilestones(user, clients)
      .then(() => (onContinue ? onContinue() : navigate("/")))
      .catch(errorHandler);
  };

  const timelineOppositeContentSx = {
    flex: 0.1, // Adjust flex size for the date section
    textAlign: "left", // Always left-aligned
  };

  const timelineContentSx = {
    flex: 0.9, // Adjust flex size for the content section
  };

  return (
    <div>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {user.theoryTrainingPlan &&
        milestoneDates.theoryEndDate &&
        milestoneDates.medicalExamDate &&
        milestoneDates.theoryExamDate ? (
          <>
            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(new Date(user.theoryTrainingPlan.startDate), "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Start online training")}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.theoryEndDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Finish online training")}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.medicalExamDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Take the medical exam")}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.theoryExamDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Take the commercial learner's permit exam")}
              </TimelineContent>
            </TimelineItem>
          </>
        ) : (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="grey" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={timelineContentSx}>
              <Trans
                t={t}
                defaults="<bold>Online Training.</bold> Study online for ~30 hours."
                components={{ bold: <b /> }}
              />
            </TimelineContent>
          </TimelineItem>
        )}

        {/* Skills training timeline items */}
        {milestoneDates.skillsTrainingStartDate &&
        milestoneDates.skillsTrainingEndDate &&
        milestoneDates.licenseExamDate ? (
          <>
            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.skillsTrainingStartDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Start skills training")}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.skillsTrainingEndDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Finish skills training")}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent sx={timelineOppositeContentSx}>
                <Typography variant="body2" fontFamily="Space Grotesk">
                  {format(milestoneDates.licenseExamDate, "MMM d")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={timelineContentSx}>
                {t("Take road test CDL exam")}
              </TimelineContent>
            </TimelineItem>
          </>
        ) : (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="grey" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={timelineContentSx}>
              <Trans
                t={t}
                defaults="<bold>Skills Training.</bold> 3-10 weeks of in-person driving training."
                components={{ bold: <b /> }}
              />
            </TimelineContent>
          </TimelineItem>
        )}

        {/* Job search timeline items */}
        {user.jobPreference && milestoneDates.allDatesPresent ? (
          <TimelineItem>
            <TimelineOppositeContent sx={timelineOppositeContentSx}>
              <Typography variant="body2" fontFamily="Space Grotesk">
                {format(addDays(milestoneDates.licenseExamDate, 30), "MMM d")}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="success" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={timelineContentSx}>
              {t("Get my first job offer")}
            </TimelineContent>
          </TimelineItem>
        ) : (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="grey" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={timelineContentSx}>
              <Trans
                t={t}
                defaults="<bold>Job search.</bold> 90% of our grads land their first job within 30 days!"
                components={{ bold: <b /> }}
              />
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>

      {milestoneDates.allDatesPresent ? (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleFinish}
        >
          {t("Finish")}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onContinue}
        >
          {t("Continue")}
        </Button>
      )}
    </div>
  );
}
