import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import {
  BackgroundFormData,
  CreateBackgroundFormData,
} from "src/pages/BackgroundSurveyScreen/BackgroundSurveyView";
import DriverLicenseInactivityReason from "src/pages/BackgroundSurveyScreen/DriverLicenseInactivityReason.enum";
import SocialBenefits from "src/pages/BackgroundSurveyScreen/SocialBenefits.enum";
import USState from "src/types/states";
import { UserAccount } from "src/types/User";
import Citizenship from "src/utils/Citizenship.enum";
import Disability from "src/utils/Disability.enum";
import EducationalAttainment from "src/utils/EducationalAttainment";
import EmploymentStatus from "src/utils/EmploymentStatus";
import Gender from "src/utils/Gender.enum";
import HousingSituation from "src/utils/HousingSituation";
import isBackgroundGuard from "src/utils/isBackgroundGuard";
import MaritalStatus from "src/utils/MaritalStatus.enum";
import Race from "src/utils/Race.enum";
import serviceFunction from "src/utils/serviceFunction";
import TransportationSituation from "src/utils/TransportationSituation";
import YesNoSkip from "src/utils/YesNoSkip";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

const JoiSchema: Record<
  keyof CreateBackgroundFormData | "user",
  Joi.AnySchema
> = {
  user: Joi.object().required(),
  hasProficientEnglish: Joi.boolean().required(),
  hasBeenIncarcerated: Joi.boolean().optional(),
  hasDriverLicense: Joi.boolean().required(),
  zipcode: Joi.string().optional(),
  // can tie it to driver license
  driverLicenseReinstatementCost: Joi.number().optional(),
  driverLicenseExpirationDate: Joi.date().optional(),
  driverLicenseNumber: Joi.string().optional(),
  driverLicenseState: Joi.string()
    .valid(...Object.keys(USState))
    .optional(),
  driverLicenseIssueDate: Joi.date().optional(),
  driverLicenseInactiveReason: Joi.string()
    .valid(...Object.keys(DriverLicenseInactivityReason))
    .optional(),
  hasEverHadDriverLicense: Joi.boolean().optional(),
  hasToTakeMandatoryCourseToReinstateLicense: Joi.boolean().optional(),
  hasMajorTrafficViolationOverPast3Years: Joi.boolean().optional(),
  // criminal
  isFirstTimeOffender: Joi.boolean().optional(),
  nextCourtDate: Joi.date().optional(),
  nextParoleHearingDate: Joi.date().optional(),
  // medical
  hasHistoryChronicalIllness: Joi.boolean().required(),
  hasBeenTerminated: Joi.boolean().required(),
  hasSexOffense: Joi.boolean().optional(),
  hasPendingCases: Joi.boolean().required(),
  numberDependents: Joi.number().required(),
  numberDUIPast7Years: Joi.number().required(),
  numberDUIRecordLifetime: Joi.number().required(),
  householdIndividualsNumber: Joi.number().required(),
  numberPointsDriverRecord: Joi.number().required(),
  gender: Joi.string()
    .valid(...Object.keys(Gender))
    .required(),
  race: Joi.array()
    .items(Joi.string().valid(...Object.keys(Race)))
    .required(),
  education: Joi.string()
    .valid(...Object.keys(EducationalAttainment))
    .required(),
  disability: Joi.string()
    .valid(...Object.keys(YesNoSkip))
    .required(),
  disabilityType: Joi.string()
    .valid(...Object.keys(Disability))
    .optional(),
  citizenship: Joi.string()
    .valid(...Object.keys(Citizenship))
    .required(),
  isHispanic: Joi.boolean().required(),
  maritalStatus: Joi.string()
    .valid(...Object.keys(MaritalStatus))
    .required(),
  isIncarcerated: Joi.boolean().required(),
  employmentStatus: Joi.string()
    .valid(...Object.keys(EmploymentStatus))
    .required(),
  benefits: Joi.array()
    .items(Joi.string().valid(...Object.keys(SocialBenefits)))
    .required(),
  hasToPayChildSupport: Joi.boolean().optional(),
  isCurrentOnChildSupport: Joi.boolean().optional(),
  totalIncomeLastSixMonths: Joi.number().required(),
  canPassVisionTest: Joi.boolean().required(),
  isTakingMedication: Joi.boolean().required(),
  lgbtq: Joi.string()
    .valid(...Object.keys(YesNoSkip))
    .required(),
  isVeteran: Joi.boolean().required(),
  isMilitarySpouse: Joi.boolean().required(),
  ssn: Joi.string().optional().allow(""),
  // TODO: tie it to is incarcerated or has been incarcerated
  isProbationOrParole: Joi.boolean().optional(),
  hasBeenProbationOrParole: Joi.boolean().optional(),
  isJusticeImpacted: Joi.boolean().optional(),
  hasDoneDiversionProgram: Joi.boolean().optional(),
  releaseDate: Joi.date().optional().allow(null),
  lastReleased: Joi.alternatives().conditional("hasBeenIncarcerated", {
    is: true,
    then: Joi.date().required(),
    otherwise: Joi.any(),
  }),
  hasViolentOffense: Joi.boolean().optional(),
  totalIncarcerationDuration: Joi.number().optional(),
  postReleaseTransportation: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string()
      .valid(...Object.keys(TransportationSituation))
      .required(),
    otherwise: Joi.any(),
  }),
  postReleaseCity: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  postReleaseState: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string()
      .valid(...Object.keys(USState))
      .required(),
    otherwise: Joi.any(),
  }),
  postReleaseHousing: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string()
      .valid(...Object.keys(HousingSituation))
      .required(),
    otherwise: Joi.any(),
  }),
  housingUnit: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  incarcerationIdentifier: Joi.alternatives().conditional("isIncarcerated", {
    is: true,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  transportation: Joi.string()
    .valid(...Object.keys(TransportationSituation))
    .optional(),
  housing: Joi.string()
    .valid(...Object.keys(HousingSituation))
    .optional(),
  // financial
  salary: Joi.number().optional(),
};
const schema = Joi.object<
  BackgroundFormData & { user: Pick<UserAccount, "uid"> }
>(JoiSchema);

const createBackground = serviceFunction(
  schema,
  async (
    {
      user,
      releaseDate,
      lastReleased,
      driverLicenseExpirationDate,
      driverLicenseIssueDate,
      ...rest
    },
    { db }
  ): Promise<BackgroundFormData> => {
    await setDoc(doc(db, "backgrounds", user.uid), {
      ...rest,
      ...generateFirestoreTimestamps(new Date(), "createdAt"),
      ...(releaseDate
        ? generateFirestoreTimestamps(releaseDate, "releaseDate")
        : {}),
      ...(lastReleased
        ? generateFirestoreTimestamps(lastReleased, "lastReleased")
        : {}),
      ...generateFirestoreTimestamps(
        driverLicenseIssueDate,
        "driverLicenseIssueDate"
      ),
      ...generateFirestoreTimestamps(
        driverLicenseExpirationDate,
        "driverLicenseExpirationDate"
      ),
    });

    const firestoreDoc = await getDoc(doc(db, "backgrounds", user.uid));

    const background = cleanFirestoreDoc(firestoreDoc);

    if (!isBackgroundGuard(background)) {
      throw new Error("Something went wrong");
    }

    await updateDoc(doc(db, "users", user.uid), {
      application: {
        ...rest,
        ...generateFirestoreTimestamps(new Date(), "createdAt"),
        ...(releaseDate
          ? generateFirestoreTimestamps(releaseDate, "releaseDate")
          : {}),
        ...(lastReleased
          ? generateFirestoreTimestamps(lastReleased, "lastReleased")
          : {}),
        ...generateFirestoreTimestamps(
          driverLicenseIssueDate,
          "driverLicenseIssueDate"
        ),
        ...generateFirestoreTimestamps(
          driverLicenseExpirationDate,
          "driverLicenseExpirationDate"
        ),
      },
    });

    return background;
  }
);

export default createBackground;
