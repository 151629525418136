import { AccessAlarm } from "@mui/icons-material";
import {
  Box,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { format, isBefore } from "date-fns";
import { t } from "i18next";
import { theme } from "src/theme";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";

type Props = {
  milestone: StudentPlanMilestone;
  tasks?: MilestoneTask[];
};

function MilestoneCardHeader({ milestone, tasks }: Props) {
  const overdueTasks = tasks
    ? tasks.filter(
        (task) =>
          !task.completedAt && isBefore(new Date(task.deadline), new Date())
      )
    : [];

  if (!tasks) return <Skeleton variant="rectangular" height={100} />;

  return (
    <Stack direction="column" spacing={2}>
      <Stack spacing={2} direction="row">
        <Box>
          {isBefore(new Date(milestone.date), new Date()) ? (
            <Box
              display="inline-block"
              sx={(theme) => ({
                backgroundColor: theme.palette.warning.light,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
              })}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AccessAlarm color="warning" fontSize="small" />
                <Typography>
                  {`${t("Due")} ${format(new Date(milestone.date), "MMM d")}`}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <Box
              display="inline-block"
              sx={(theme) => ({
                backgroundColor: theme.palette.secondary.light,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
              })}
            >
              <Typography display="inline">{`Due ${format(
                new Date(milestone.date),
                "MMM d"
              )}`}</Typography>
            </Box>
          )}
        </Box>
        <Box>
          {overdueTasks.length > 0 && (
            <Box
              display="inline-block"
              sx={(theme) => ({
                backgroundColor: theme.palette.warning.light,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
              })}
            >
              <Stack spacing={1} direction="row">
                <AccessAlarm
                  sx={(theme) => ({ color: theme.palette.warning.dark })}
                />
                <Typography>
                  {overdueTasks.length} {t("overdue tasks")}
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>

      <Stack direction="row" gap={theme.spacing(1)} alignItems="center">
        <Stack spacing={1}>
          <Typography variant="h6">{milestone.title}</Typography>
          {tasks.length > 0 && (
            <Box>
              <LinearProgress
                variant="determinate"
                value={
                  (tasks.filter((task) => task.completedAt).length /
                    tasks.length) *
                  100
                }
              />
              <Typography variant="body2" color="text.secondary" mt={0.5}>
                {tasks.filter((task) => task.completedAt).length} /
                {tasks.length} {t("tasks completed")}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default MilestoneCardHeader;
