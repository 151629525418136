import { Add, CalendarMonth } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Fade,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { differenceInDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Empty from "src/components/Empty";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import createStudentMilestonePlan from "src/firebase/createStudentEmploymentMilestonePlan";
import deleteMilestone from "src/firebase/deleteMilestone";
import fetchStudentPlanMilestones from "src/firebase/fetchStudentPlanMilestones";
import updateMilestone from "src/firebase/updateMilestone";
import updateMilestoneCompletion from "src/firebase/updateMilestoneCompletion";
import AppSkeleton from "src/pages/AppSkeleton";
import { useSnackbarContext } from "src/SnackbarProvider";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import { UserAccount } from "src/types/User";
import replaceItem from "src/utils/replaceItem";
import sortBy from "src/utils/sortBy";
import useErrorHandler from "src/utils/useErrorHandler";
import MilestoneCard from "./MilestoneCard";
import StudentPlanMilestoneForm, {
  REQUIRED_MILESTONES,
  StudentPlanMilestoneFormData,
} from "./StudentPlanMilestoneForm";

interface Props {
  client: UserAccount;
  minified?: boolean;
  cutoffDays?: number;
  // TODO: refactor this props, only makes sense in certain contexts
  onReviewSuccessPlan?: () => void;
}

export default function StudentPlanTab({
  client,
  minified,
  cutoffDays,
  onReviewSuccessPlan,
}: Props): JSX.Element {
  const [milestones, setMilestones] = useState<StudentPlanMilestone[]>();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingMilestone, setEditingMilestone] =
    useState<StudentPlanMilestone>();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const { t } = useTranslation();
  const { alert } = useSnackbarContext();
  const navigate = useNavigate();

  useEffect(() => {
    fetchStudentPlanMilestones(client.uid, clients)
      .then((milestones) =>
        setMilestones(
          cutoffDays
            ? sortBy(
                milestones.filter((milestone) => {
                  if (milestone.completedAt) return false;
                  const daysUntilDue = differenceInDays(
                    new Date(milestone.date),
                    new Date()
                  );
                  return daysUntilDue <= cutoffDays || daysUntilDue < 0;
                }),
                (item) => new Date(item.date).getTime()
              )
            : sortBy(milestones, (item) => new Date(item.date).getTime())
        )
      )
      .catch(errorHandler);
  }, [client, errorHandler, clients, cutoffDays]);

  const handleFormClose = () => {
    setIsFormOpen(false);
  };

  const handleCreateMilestone = async ({
    date,
    ...data
  }: StudentPlanMilestoneFormData) => {
    await createStudentMilestonePlan(
      { ...data, date: new Date(date), user: client },
      clients
    )
      .then((milestone) => {
        setMilestones((prev) =>
          sortBy([...(prev || []), milestone], (item) =>
            new Date(item.date).getTime()
          )
        );
        handleFormClose();
      })
      .catch(errorHandler);
  };

  const handleMilestoneCompletion = async (milestone: StudentPlanMilestone) => {
    const updatedCompleted = !milestone.completedAt;

    await updateMilestoneCompletion(
      {
        user: client,
        milestone,
        completed: updatedCompleted,
      },
      clients
    )
      .then((updated) => {
        const { completedAt, completedAtFirestoreTimestamp } = updated;

        setMilestones((prev) => {
          const updated = replaceItem(
            prev || [],
            milestone,
            { ...milestone, completedAt, completedAtFirestoreTimestamp },
            (item) => item.uid === milestone.uid
          );

          return [...updated];
        });
      })
      .catch(errorHandler);
  };

  const handleUpdate = async (
    milestone: StudentPlanMilestone,
    data: StudentPlanMilestoneFormData
  ) => {
    await updateMilestone({ milestone, user: client, ...data }, clients)
      .then(() => {
        setMilestones((prev) => {
          const updated = replaceItem(
            prev || [],
            milestone,
            { ...milestone, ...data, date: data.date.toISOString() },
            (item) => item.uid === milestone.uid
          );

          return [...updated];
        });
        setEditingMilestone(undefined);
        alert("success", t("Milestone updated successfully"));
      })
      .catch(errorHandler);
  };

  const handleDeletion = async (milestone: StudentPlanMilestone) => {
    await deleteMilestone({ milestone, user: client }, clients)
      .then(() =>
        setMilestones((prev) =>
          prev?.filter((item) => item.uid !== milestone.uid)
        )
      )
      .catch(errorHandler);
  };

  const missingMilestones = useMemo(() => {
    if (!milestones) return undefined;
    return REQUIRED_MILESTONES.filter(
      (milestone) => !milestones.some((item) => item.type === milestone.type)
    );
  }, [milestones]);

  if (!milestones) return <AppSkeleton />;

  if (minified) {
    return (
      <Box>
        <Stack spacing={2}>
          {milestones.length > 0 &&
            milestones.map((milestone, index) => (
              <Fade in timeout={500 * (index + 1)} key={milestone.uid}>
                <Box>
                  <MilestoneCard
                    milestone={milestone}
                    handleCompletion={() =>
                      handleMilestoneCompletion(milestone)
                    }
                    handleDeletion={() => handleDeletion(milestone)}
                    handleEdit={() => setEditingMilestone(milestone)}
                    minified={minified}
                  />
                </Box>
              </Fade>
            ))}

          {milestones.length > 0 ? (
            <Box>
              {onReviewSuccessPlan && (
                <Button onClick={() => onReviewSuccessPlan}>
                  {t("View All Milestones")}
                </Button>
              )}
            </Box>
          ) : (
            <Box>
              <Empty
                description="Nothing is coming up in the next two weeks!"
                cta={{
                  label: t("Review Success Plan"),
                  onClick: () =>
                    onReviewSuccessPlan
                      ? onReviewSuccessPlan()
                      : navigate("/success-plan"),
                  variant: "text",
                }}
              />
            </Box>
          )}
        </Stack>
      </Box>
    );
  }

  return (
    <Stack>
      <Box marginLeft="auto" />

      <Stack spacing={2}>
        {milestones.length > 0 &&
          milestones.map((milestone) => (
            <MilestoneCard
              milestone={milestone}
              handleCompletion={() => handleMilestoneCompletion(milestone)}
              handleDeletion={() => handleDeletion(milestone)}
              handleEdit={() => setEditingMilestone(milestone)}
              minified={minified}
              key={milestone.uid}
            />
          ))}
      </Stack>
      {!milestones.length && (
        <Empty
          cta={{
            onClick: () => setIsFormOpen(true),
            label: t("Create Milestone"),
          }}
        />
      )}
      {missingMilestones?.length ? (
        <Card variant="outlined" sx={{ marginTop: 4 }}>
          <CardContent>
            <Typography>
              {t("Your plan is missing the following core milestones:")}
            </Typography>
            {missingMilestones.map((milestone) => (
              <Typography key={`missing-${milestone.type}`}>
                - {t(milestone.name)}
              </Typography>
            ))}
          </CardContent>
          <CardActions>
            <Button
              onClick={() => setIsFormOpen(true)}
              variant="outlined"
              startIcon={<CalendarMonth />}
            >
              {t("Create Milestone")}
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Box marginLeft="auto" sx={{ marginTop: 4 }}>
          <Button
            onClick={() => setIsFormOpen(true)}
            variant="outlined"
            startIcon={<Add />}
          >
            {t("Create Custom Milestone")}
          </Button>
        </Box>
      )}

      <Modal open={isFormOpen} onClose={handleFormClose}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <StudentPlanMilestoneForm
                  onSubmit={handleCreateMilestone}
                  availableMilestoneTypes={
                    missingMilestones
                      ? REQUIRED_MILESTONES.filter((item) =>
                          missingMilestones.includes(item)
                        )
                      : REQUIRED_MILESTONES
                  }
                />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
      <Modal
        open={!!editingMilestone}
        onClose={() => setEditingMilestone(undefined)}
      >
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                {!!editingMilestone && (
                  <StudentPlanMilestoneForm
                    onSubmit={(data) => handleUpdate(editingMilestone, data)}
                    defaultValues={{
                      ...editingMilestone,
                      date: new Date(editingMilestone.date),
                    }}
                    availableMilestoneTypes={
                      missingMilestones
                        ? REQUIRED_MILESTONES.filter(
                            (item) =>
                              missingMilestones.includes(item) ||
                              editingMilestone.type === item.type
                          )
                        : REQUIRED_MILESTONES
                    }
                  />
                )}
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Stack>
  );
}
