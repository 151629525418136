import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  MobileStepper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSession } from "src/contexts/AuthContext";
// TODO: need to update cached resource reference
// import { useLiveQuery } from "dexie-react-hooks";
// import { db } from "src/api/db";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ArticleOutlined,
  Info,
  KeyboardArrowLeft,
  VideocamOffOutlined,
} from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { OnProgressProps } from "react-player/base";
import { useNavigate } from "react-router-dom";
import BackHomeButton from "src/components/Button/BackHomeButton";
import QuizCard from "src/components/Card/QuizCard";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { useCurrentUser } from "src/SessionBoundary";
import { useSnackbarContext } from "src/SnackbarProvider";
import hasCompletedVideo from "src/utils/hasCompletedVideo";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import useResourceProgress from "src/utils/useResourceProgress";
import AppSkeleton from "../AppSkeleton";
import { GetResourcesByUnitDocument } from "../UnitPage/GetResourcesByUnit.generated";
import { GetUnitDocument } from "../UnitPage/GetUnit.generated";
import { GetResourceDocument } from "./GetResource.generated";
import ResourceScreenHeader from "./ResourceScreenHeader";
import ResourceScreenTextbooks from "./ResourceScreenTextbooks";

const VideoPlayer = lazy(() => import("src/components/VideoPlayer"));

type Props = {
  resourceId: string;
  disableAutoSkipToNextSection?: boolean;
  onBack: () => void;
};

export default function ResourceView({
  resourceId,
  disableAutoSkipToNextSection,
  onBack,
}: Props) {
  const [isReadModeOn, setIsReadModeOn] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();
  const session = useSession();
  const snackbarContext = useSnackbarContext();
  const me = useCurrentUser();
  const { findGradeByQuizId } = useCompleteQuizGrades(me.uid);

  const { data: resourceData, loading: resourceLoading } = useQuery(
    GetResourceDocument,
    { variables: { resourceId }, onError: errorHandler }
  );

  // needs to be lazy to load resources later
  const [getResourcesByUnit, { data: allUnitResourcesData }] = useLazyQuery(
    GetResourcesByUnitDocument,
    { onError: errorHandler }
  );

  const resource = resourceData?.resource;
  const allUnitResources = allUnitResourcesData?.allResources;
  const [start, setStart] = useState<number>();
  const [isQuizEnabled, setIsQuizEnabled] = useState(false);

  const grade = resource?.quiz
    ? findGradeByQuizId(resource.quiz.id)
    : undefined;

  const quizCompleted = !!grade || !resource?.quiz;

  const { findResourceProgressById, loading } = useResourceProgress();

  const [getUnit] = useLazyQuery(GetUnitDocument);

  const videoProgress = useMemo(
    () => findResourceProgressById(resourceId),
    [resourceId, findResourceProgressById]
  );

  useEffect(() => {
    if (!resource) return;
    getResourcesByUnit({ variables: { unitId: resource.unit.id } });
  }, [resource, getResourcesByUnit]);

  // initial video load

  useEffect(() => {
    if (typeof start !== "undefined" || loading) return;

    if (videoProgress) {
      setStart(videoProgress.progressSeconds);
      setIsQuizEnabled(
        hasCompletedVideo(videoProgress.progressFraction) ||
          !!videoProgress.hasCompleted
      );
    } else {
      setStart(0);
    }
  }, [resourceId, start, videoProgress, loading]);

  const handleVideoProgress = async (state: OnProgressProps) => {
    await session
      .setUserResourceProgress(resourceId, {
        resourceId,
        progressFraction: state.played,
        progressSeconds: Math.round(state.playedSeconds),
        hasCompleted: videoProgress?.hasCompleted // if the person has already completed it once, no need to go back
          ? true
          : hasCompletedVideo(state.played)
          ? true
          : undefined,
      })
      .catch(Sentry.captureException);

    if (
      videoProgress &&
      !videoProgress.hasCompleted &&
      hasCompletedVideo(state.played)
    ) {
      setIsQuizEnabled(state.played >= 0.8);
    }
  };

  const handleResourceNavigation = useCallback(
    async (operation: "next" | "back") => {
      if (!resource || !allUnitResources) throw new Error(t("Type narrowing"));

      if (disableAutoSkipToNextSection) return navigate("/");

      const operator = operation === "next" ? 1 : -1;

      const nextResource = allUnitResources.find(
        (res) => res.order === resource.order + operator
      );

      if (nextResource) return navigate(`/resources/${nextResource.id}`);

      // navigate to next unit
      const { data: nextUnit } = await getUnit({
        variables: { order: resource.unit.order + operator },
      });

      snackbarContext.alert(
        "success",
        t("Congrats on being done with this unit 🎉!")
      );

      return nextUnit?.unit
        ? navigate(`/units/${nextUnit.unit.id}`)
        : navigate("/");
    },
    [
      allUnitResources,
      navigate,
      getUnit,
      resource,
      snackbarContext,
      t,
      disableAutoSkipToNextSection,
    ]
  );

  const handleVideoEnded = useCallback(async () => {
    if (!resource || !allUnitResources)
      throw new Error(t("Cannot move directly to next video"));

    await session
      .setUserResourceProgress(resource.id, {
        resourceId: resource.id,
        progressFraction: 1,
        progressSeconds: resource.duration * 60,
        hasCompleted: true,
      })
      .catch(Sentry.captureException);

    setIsQuizEnabled(true);
  }, [resource, allUnitResources, t, session]);

  if (!resource || typeof start === "undefined" || loading)
    return <AppSkeleton />;

  // on iOS, the backblaze video is not loading for many devices
  // fallback URL is a temporary fix to let students study
  //

  const videoUrl = isMobileOnly ? resource.fallbackUrl : resource.url;

  return (
    <Box>
      <BackHomeButton onBack={onBack} />
      {/* <Header
        title={resource.name}
        section={resource.unit.name}
        onBack={() => navigate(`/units/${resource.unit.id}`)}
      /> */}
      <ResourceScreenHeader unit={resource.unit} />

      {isQuizEnabled && (
        <Alert icon={<Info fontSize="inherit" />} severity="success">
          {t("Congrats on being done with another video!")}
        </Alert>
      )}
      <Grid container paddingLeft={{ md: 4 }}>
        <Grid item xs={12} md={8} marginY={{ md: 8 }}>
          {resourceLoading && (
            <CircularProgress
              sx={{ marginX: "auto", marginY: theme.spacing(2) }}
            />
          )}
          <Box sx={[isReadModeOn && { display: "none" }]}>
            <Suspense fallback={<CardSkeleton />}>
              <VideoPlayer
                start={start}
                url={videoUrl}
                width="100%"
                playsInline
                onProgress={handleVideoProgress}
                progressInterval={5000}
                onError={errorHandler}
                onEnded={handleVideoEnded}
                fallback={<Typography>{t("Loading")}</Typography>}
                controls
              />
            </Suspense>
          </Box>
          <Card>
            <CardContent>
              {resource.quiz && (
                <QuizCard quiz={resource.quiz} locked={!isQuizEnabled} />
              )}
              <Typography variant="body1">{resource.description}</Typography>
            </CardContent>
            <CardActions>
              {/* TODO: reactivate this */}
              {resource.textbookChapters.length > 10000 && (
                <Button
                  onClick={() => setIsReadModeOn((pip) => !pip)}
                  startIcon={
                    isReadModeOn ? <VideocamOffOutlined /> : <ArticleOutlined />
                  }
                >
                  {isReadModeOn ? t("View video") : t("Read notes")}
                </Button>
              )}
            </CardActions>
          </Card>
          {isReadModeOn && (
            <Box>
              <ResourceScreenTextbooks chapters={resource.textbookChapters} />
            </Box>
          )}
          {allUnitResources && (
            <MobileStepper
              sx={{
                borderTop: 1,
                borderColor: theme.palette.grey[300],
                position: "fixed",
                bottom: 0,
                width: "100vw",
              }}
              elevation={1}
              variant="text"
              steps={allUnitResources.length}
              position="bottom"
              activeStep={resource.order}
              nextButton={
                <Button
                  disabled={!quizCompleted || !videoProgress?.hasCompleted}
                  variant="contained"
                  size="large"
                  onClick={() => handleResourceNavigation("next")}
                >
                  {t("Next Lesson")}
                </Button>
              }
              backButton={
                <Button onClick={() => handleResourceNavigation("back")}>
                  <KeyboardArrowLeft />
                  {t("Previous")}
                </Button>
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
