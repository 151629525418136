import { Timestamp } from "firebase/firestore";

export enum StudentReferenceType {
  Personal = "personal",
  professional = "professional",
}

export enum ReferenceStatus {
  not_verified = "Not Verified",
  ready_to_verify = "Ready to Verify",

  // end status
  declined = "Declined",
  verified = "Verified",
  verified_subscribed = "Verified and Subscribed",
  unresponsive = "Unresponsive",
}

export type StudentReference = {
  uid: string;
  type: StudentReferenceType;
  firstName: string;
  lastName: string;
  relationship: string;
  phone: string;
  email: string;
  userId: string;
  createdAt: string;
  createdAtFirestoreTimestamp: Timestamp;
  // TODO: new fields that need backfilling
  lastUpdatedAt?: string;
  lastUpdatedAtFirestoreTimestamp?: Timestamp;
  status: keyof typeof ReferenceStatus;
  statusDetails?: string;
  internalStatusDetails?: string;

  // LEGACY
  verifiedAt?: string;
  verifiedAtFirestoreTimestamp?: Timestamp;
  subscribedAt?: string;
  subscribedAtFirestoreTimestamp?: Timestamp;
};
