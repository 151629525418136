import { Card, CardContent, CardHeader, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PaddedLayout } from "src/components/Layout";
import StudentPlanTab from "src/pages/ClientScreen/StudentPlanTab/StudentPlanTab";
import OnboardingScreen from "src/pages/OnboardingScreen";
import StudentReferenceSection from "src/pages/OnboardingScreen/StudentReferenceSection";
import { useCurrentUser } from "src/SessionBoundary";
import { theme } from "src/theme";
import isUserOnboarded from "src/utils/isUserOnboarded";
import DocumentList from "../DocumentsTab";

export default function ProfileScreen() {
  const { t } = useTranslation();
  const user = useCurrentUser();

  if (!user.enrollmentStatus) return <OnboardingScreen />;

  return (
    <PaddedLayout>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" gap={theme.spacing(2)}>
            <DocumentList
              client={user}
              showMilestones={isUserOnboarded(user)}
            />
            <Card>
              <CardHeader title={t("My Success Plan")} />
              <CardContent>
                <StudentPlanTab client={user} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={t("References")} />
              <CardContent>
                <StudentReferenceSection client={user} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </PaddedLayout>
  );
}
