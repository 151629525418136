import { Avatar, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledTab from "src/components/Tabs/StyledTab";
import StyledTabs from "src/components/Tabs/StyledTabs";
import { useAppContext } from "src/contexts/AppContext";
import fetchNewChatMessageCount from "src/firebase/fetchNewChatMessageCount";
import {
  useCurrentUser,
  useSessionAccountInformation,
} from "src/SessionBoundary";
import offlineModeEnabled from "src/utils/offlineModeEnabled";
import useErrorHandler from "src/utils/useErrorHandler";

export type DashboardTab =
  | "home"
  | "grades"
  | "resources"
  | "saved"
  | "employers"
  | "chat"
  | "range"
  | "success_plan";

type Props = {
  onChange: (DashboardTab: DashboardTab) => void;
  value: DashboardTab;
};

const DashboardTabs = ({ onChange, value }: Props) => {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const [unreadCount, setUnreadCount] = useState(0);
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const account = useSessionAccountInformation();

  useEffect(() => {
    // Define a function to fetch new chat message count
    const fetchMessages = () => {
      fetchNewChatMessageCount(account.uid, account.uid, clients)
        .then(setUnreadCount)
        .catch(errorHandler);
    };

    // Immediately invoke the function upon mounting
    fetchMessages();

    // Set up an interval to run the function every minute (60000 milliseconds)
    const intervalId = setInterval(fetchMessages, 60000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [account, errorHandler, clients]);

  const tabs: { label: string; value: DashboardTab; badgeCount?: number }[] =
    useMemo(
      () =>
        [
          user.enrollmentStatus === "skills_training" ||
          user.enrollmentStatus === "completed_skills_training"
            ? { label: t("Skills Training"), value: "range" }
            : undefined,
          { label: t("Course"), value: "home" },
          { label: t("Coach"), value: "chat", badgeCount: unreadCount },
          user.enrollmentStatus ||
          user.applicationStatus === "conditionally_enrolled" ||
          (user.applicationStatus === "enrolled" &&
            user.applicationStatus &&
            user.cohortId !== "PEAqzBQFGoKnuvnKIZUg")
            ? { label: t("Grades"), value: "grades" }
            : undefined,
          user.enrollmentStatus ||
          user.applicationStatus === "conditionally_enrolled" ||
          user.applicationStatus === "enrolled"
            ? { label: t("Success Plan"), value: "success_plan" }
            : undefined,
          { label: t("Profile"), value: "resources" },
        ].filter((tab) => !!tab) as {
          label: string;
          value: DashboardTab;
          badgeCount?: number;
        }[],
      [user, t, unreadCount]
    );

  return (
    <StyledTabs
      aria-label="Course Tools"
      scrollButtons="auto"
      value={value}
      variant="scrollable"
    >
      {tabs.map((tab) => (
        <StyledTab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          onClick={() => onChange(tab.value)}
          iconPosition="end"
          icon={
            tab.badgeCount && tab.badgeCount > 0 ? (
              <Avatar
                sx={{
                  backgroundColor: theme.palette.error.main,
                  width: 20,
                  height: 20,
                  fontSize: 12,
                }}
              >
                {tab.badgeCount}
              </Avatar>
            ) : undefined
          }
        />
      ))}
      {offlineModeEnabled() && <StyledTab label="Saved" value="saved" />}
    </StyledTabs>
  );
};

export default DashboardTabs;
