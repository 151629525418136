import { t } from "i18next";

export type VideoAskItem = {
  formId: string;
  label: string;
  shareId: string;
};

const TEST_INTERVIEW = {
  formId: "ae487cb8-f308-4e78-8590-70f0fa3a654d",
  shareId: "fwi0cmap6",
  label: "Test Interview",
};

const VideoAskSchema: Record<string, VideoAskItem> = {
  FIRST_INTERVIEW:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "29c98395-f254-4966-ad5e-242a5f5bc8c6",
          shareId: "forbuhp81",
          label: t("First Interview"),
        },
  SUCCESS_PLAN:
    process.env.NODE_ENV === "development"
      ? TEST_INTERVIEW
      : {
          formId: "15b0f1cb-7587-498c-93a1-fb426d450fca",
          shareId: "faabhf1c5",
          label: t("Success Plan"),
        },
};

export default VideoAskSchema;
