import { ApolloClient, InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: "https://graphql.datocms.com/",
  cache,
  credentials: "same-origin",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_DATO_CMS_API_KEY}`,
    "X-Environment": "copy-2024-04-01",
  },
});

export default client;
