import { doc, getDoc, updateDoc } from "firebase/firestore";
import Joi from "joi";
import { StudentReference } from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import isStudentReferenceGuard from "src/utils/isStudentReferenceGuard";
import serviceFunction from "src/utils/serviceFunction";
import cleanFirestoreDoc from "./cleanFirestoreDoc";
import generateFirestoreTimestamps from "./generateFirestoreTimestamps";

type Data = {
  user: UserAccount;
  payload: Partial<StudentReference> & Pick<StudentReference, "uid">;
};

const schema = Joi.object<Data>({
  user: Joi.object().required(),
  payload: Joi.object().required(),
});

const updateReference = serviceFunction(
  schema,
  async ({ user, payload }, { db }): Promise<StudentReference> => {
    const { uid, ...rest } = payload;
    await updateDoc(doc(db, `users/${user.uid}/references/${payload.uid}`), {
      ...rest,
      ...generateFirestoreTimestamps(new Date(), "lastUpdatedAt"),
    });
    const updated = cleanFirestoreDoc(
      await getDoc(doc(db, `users/${user.uid}/references/${payload.uid}`))
    );

    if (!isStudentReferenceGuard(updated))
      throw new Error("The update had malformed data");
    return updated;
  }
);

export default updateReference;
