import { useQuery } from "@apollo/client";
import {
  DocumentScannerOutlined,
  GradeOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CardSkeleton } from "src/components/Skeleton/closet";
import { GetUnitsDocument } from "src/pages/DashboardPage/GetUnits.generated";
import { UserAccount } from "src/types/User";
import calculateAverage from "src/utils/calculateAverage";
import calculateUnitsProgress from "src/utils/calculateUnitsProgress";
import { CLP_ID } from "src/utils/constants";
import useCompleteQuizGrades from "src/utils/useCompleteQuizGrades";
import useErrorHandler from "src/utils/useErrorHandler";
import useResourceProgress from "src/utils/useResourceProgress";

type Props = { client: UserAccount; handleClick?: () => void };

export default function DashboardTrainingProgressCard({
  client,
  handleClick,
}: Props) {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { data } = useQuery(GetUnitsDocument, {
    variables: { courseId: CLP_ID },
    onError: errorHandler,
  });
  const { completeReport } = useCompleteQuizGrades(client.uid);
  const { progress } = useResourceProgress(client.uid);

  const units = data?.allUnits;
  if (!units || !progress || !completeReport) return <CardSkeleton />;

  const { totalVideos, totalSeen } = calculateUnitsProgress(units, progress);

  const average = calculateAverage(
    Object.values(completeReport.gradeGroupAverages).filter(
      (average) => typeof average === "number"
    ) as number[]
  );

  return (
    <Card
      onClick={handleClick}
      sx={{
        cursor: handleClick ? "pointer" : "default",
        "&:hover": {
          boxShadow: handleClick ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none",
        },
      }}
    >
      <Stack>
        <CardContent>
          <Stack spacing={2}>
            <Box>
              <Typography variant="h5">{t("Learn the Theory")}</Typography>
              <Typography variant="body2">
                {t(
                  "Learn the information required to pass the permit and get behind the wheel."
                )}
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              value={
                ((totalSeen + completeReport.totalCompletedRequiredQuizzes) /
                  (totalVideos + completeReport.totalRequiredQuizzes)) *
                100
              }
            />
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <VideocamOutlined />
                <Typography variant="body2" color="text.secondary">
                  {totalSeen}/{totalVideos} {t("lessons")}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <DocumentScannerOutlined />
                <Typography variant="body2" color="text.secondary">
                  {completeReport.totalCompletedRequiredQuizzes}/
                  {completeReport.totalRequiredQuizzes} {t("exams")}
                </Typography>
              </Stack>
              {average && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <GradeOutlined />
                  <Typography variant="body2" color="text.secondary">
                    {average ? `${Math.round(average * 100)} %` : "N/A"}
                  </Typography>
                </Stack>
              )}
            </Box>
            {handleClick && (
              <Box>
                <Button variant="contained">
                  {totalSeen > 0 ? t("Resume training") : t("Start training")}
                </Button>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}
