import { Box } from "@mui/material";
import { PaddedLayout } from "src/components/Layout";
import AdminActionItemList from "src/pages/ClientScreen/AdminActionItemList";
import { useAdmin } from "src/SessionBoundary";

export default function CaseloadScreen() {
  useAdmin();

  return (
    <Box>
      <PaddedLayout>
        <AdminActionItemList />
      </PaddedLayout>
    </Box>
  );
}
