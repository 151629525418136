import { Stack, Typography, useTheme } from "@mui/material";
import Button from "src/components/Button";
import UserAvatar, { UserAvatarProps } from "src/components/UserAvatar";
import { getFullName } from "src/utils";

type Props = {
  client: UserAvatarProps["user"];
  disableNavigation?: boolean;
};

const ClientProfileLink = ({ client, disableNavigation }: Props) => {
  const theme = useTheme();

  if (disableNavigation)
    return (
      <Stack direction="row" alignItems="center" spacing={theme.spacing(1)}>
        <UserAvatar user={client} />
        <Typography>{getFullName(client)}</Typography>
      </Stack>
    );
  return (
    <Button
      onClick={() => window.open(`/admin/clients/${client.uid}`, "_blank")}
    >
      <Stack direction="row" alignItems="center" spacing={theme.spacing(1)}>
        <UserAvatar user={client} />
        <Typography>{getFullName(client)}</Typography>
      </Stack>
    </Button>
  );
};

export default ClientProfileLink;
