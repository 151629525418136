import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScreenTitle } from "src/components/Typography";
import { useCurrentUser } from "src/SessionBoundary";
import { StudentReference } from "src/types/StudentReference";
import { MIN_NUMBER_REFERENCES } from "../OnboardingScreen/OnboardingScreen";
import StudentReferenceSection from "../OnboardingScreen/StudentReferenceSection";
import SuccessScreenModal from "../SucessScreenModal";
import SupportNetworkIllustration from "./SupportNetworkIllustration.svg";

type Props = {
  onboarding?: boolean;
};

export default function ReferenceScreen({ onboarding }: Props) {
  const user = useCurrentUser();
  const { t } = useTranslation();
  const [finished, setFinished] = useState(false);

  const handleChange = (_: StudentReference, totalItems: number) => {
    if (!onboarding) return;
    if (totalItems === MIN_NUMBER_REFERENCES) {
      setFinished(true);
    }
  };

  return (
    <Box>
      <ScreenTitle>{t("Build your support network")}</ScreenTitle>
      <Typography marginTop={1}>
        {t(
          "We ask that you share the contact information for people who are close to you and will be supporting you in your journey of reentry to your community. We will contact them to let them know you are applying to join the program."
        )}
      </Typography>
      <Box marginTop={4}>
        <StudentReferenceSection onChange={handleChange} client={user} />
      </Box>
      <SuccessScreenModal
        open={finished}
        illustration={SupportNetworkIllustration}
        illustrationWidth={260}
        title={t("Nice Work!")}
        content={
          <Typography variant="body1">
            {t(
              "We will contact your references to let them know you are applying for the program. They will receive updates on your training journey."
            )}
          </Typography>
        }
        okButtonText={t(`Continue`)}
        backButtonText={t("Back Home")}
      />
    </Box>
  );
}
