import { Box, Card, CardContent, CardHeader } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RocketLottie from "src/assets/Lotties/RocketTakeoff.json";
import BackHomeButton from "src/components/Button/BackHomeButton";
import FloatingVideo from "src/components/FloatingVideo";
import VideoAskIFrame from "src/components/VideoAskIFrame";
import { useAppContext } from "src/contexts/AppContext";
import updateUserJobPreference from "src/firebase/updateUserJobPreference";
import { useCurrentUser } from "src/SessionBoundary";
import { CLP_ID, SUCCESS_PLAN_EXPLAINED_URL } from "src/utils/constants";
import useErrorHandler from "src/utils/useErrorHandler";
import useVideoAskCompletion from "src/utils/useVideoAskCompletion";
import AppSkeleton from "../AppSkeleton";
import StudentPlanTab from "../ClientScreen/StudentPlanTab/StudentPlanTab";
import SuccessScreen from "../SuccessScreen";
import { amplitudeClickHandler } from "./amplitude";
import JobPreferenceForm, { JobPreferenceFormData } from "./JobPreferenceForm";
import SchoolSelectionForm from "./SchoolSelectionForm";
import SuccessPlanReview from "./SuccessPlanReview";
import TheoryStudyPlanScreen from "./TheoryStudyPlanScreen";
import VideoAskSchema from "./videoAskIds.enum";

// Define constants for each step
enum STEPS {
  VIDEO_ASK,
  INTRO_SUCCESS_PLAN,
  THEORY_STUDY_PLAN,
  SCHOOL_SELECTION,
  JOB_PREFERENCES,
  SUCCESS_PLAN_REVIEW,
}

const LOADING_STEP = -1 as const;

export default function SuccessPlanOnboardingScreen() {
  const user = useCurrentUser();
  const errorHandler = useErrorHandler();
  const { clients } = useAppContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    completed: successPlanCompleted,
    loading: successPlanAnswersLoading,
  } = useVideoAskCompletion(user, VideoAskSchema.SUCCESS_PLAN);

  // State to track the current step
  const [currentStep, setCurrentStep] = useState<number>(LOADING_STEP);
  const [finished, setFinished] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState<boolean>(false);

  // Determine the initial step based on user progress
  useEffect(() => {
    // only set the initial step if it hasn't been set yet
    if (currentStep !== LOADING_STEP) return;

    // If the success plan answers are still loading, don't set the step
    if (successPlanAnswersLoading) return;

    if (!successPlanCompleted) {
      setCurrentStep(STEPS.VIDEO_ASK);
    } else if (!user.theoryTrainingPlan) {
      setCurrentStep(STEPS.INTRO_SUCCESS_PLAN);
    } else if (!user.schoolId) {
      setCurrentStep(STEPS.SCHOOL_SELECTION);
    } else if (!user.jobPreference) {
      setCurrentStep(STEPS.JOB_PREFERENCES);
    } else {
      setCurrentStep(STEPS.SUCCESS_PLAN_REVIEW);
    }
  }, [currentStep, successPlanAnswersLoading, successPlanCompleted, user]);

  // Handler for going to the next step
  const handleNext = () => {
    if (currentStep < STEPS.SUCCESS_PLAN_REVIEW) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Handler for going to the previous step, excluding Video Ask
  const handleBack = () => {
    if (currentStep > STEPS.THEORY_STUDY_PLAN) {
      // Start at INTRO_SUCCESS_PLAN and disable back to VIDEO_ASK
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Handler for job preferences submission
  const handleSubmitJobPreferences = (data: JobPreferenceFormData) => {
    updateUserJobPreference({ user, jobPreference: data }, clients)
      .then(handleNext)
      .catch(errorHandler);
  };

  const handleFinish = () => {
    setFinished(true);
  };

  if (successPlanAnswersLoading) return <AppSkeleton />;
  if (!user.organizationId || !user.cohortId)
    throw new Error(
      "User needs to belong to a cohort and organization to setup their plan."
    );

  if (finished) {
    return (
      <SuccessScreen
        illustration={{
          type: "react",
          component: (
            <Lottie animationData={RocketLottie} style={{ height: 150 }} />
          ),
        }}
        title={t("Here is your road to success:")}
        content={<StudentPlanTab client={user} minified />}
        okButtonText={t(`Looks good!`)}
        onOkButtonClick={amplitudeClickHandler(
          "Success Plan Onboarding Screen Completion: Click on Continue",
          () => navigate("/")
        )}
      />
    );
  }

  if (currentStep === STEPS.VIDEO_ASK) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 9999, // Ensure it's above everything
          backgroundColor: "white", // Optional: to block out what's behind it
        }}
      >
        <VideoAskIFrame
          videoAsk={VideoAskSchema.SUCCESS_PLAN}
          onFinish={handleNext} // Move to next step once finished
          contactInfo={user}
        />
      </Box>
    );
  }

  return (
    <Box
      onClick={() => {
        if (!playVideo) setPlayVideo(true);
      }}
    >
      {currentStep > STEPS.THEORY_STUDY_PLAN && (
        <BackHomeButton
          onBack={handleBack}
          label={t("Back to previous step")}
        />
      )}
      {currentStep === STEPS.INTRO_SUCCESS_PLAN && (
        <Card>
          <CardHeader title={t("Training Overview")} />
          <CardContent>
            <SuccessPlanReview onContinue={handleNext} />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.THEORY_STUDY_PLAN && (
        <Card>
          <CardHeader title={t("Your Online Training")} />
          <CardContent>
            <TheoryStudyPlanScreen courseId={CLP_ID} onSuccess={handleNext} />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.SCHOOL_SELECTION && (
        <Card>
          <CardHeader
            title={t("Your Skills Training")}
            subheader={t(
              "Learning to drive a truck takes about 120 hours. The number of weeks depends on your schedule and location."
            )}
          />
          <CardContent>
            <SchoolSelectionForm onSuccess={handleNext} />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.JOB_PREFERENCES && (
        <Card>
          <CardHeader
            title={t("Your Job Preferences")}
            subheader={t(
              "We want to make sure you can find the right job. Here you will learn how your preference impact your job options."
            )}
          />
          <CardContent>
            <JobPreferenceForm
              defaultValues={user.jobPreference}
              onSubmit={handleSubmitJobPreferences}
            />
          </CardContent>
        </Card>
      )}

      {currentStep === STEPS.SUCCESS_PLAN_REVIEW && (
        <Card>
          <CardHeader title={t("Review Your Success Plan")} />
          <CardContent>
            <SuccessPlanReview onContinue={handleFinish} />
          </CardContent>
        </Card>
      )}
      <FloatingVideo
        videoUrl={SUCCESS_PLAN_EXPLAINED_URL}
        playVideo={playVideo}
      />
    </Box>
  );
}
