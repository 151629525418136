import { AddOutlined, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "src/components/ModalContainer";
import { useAppContext } from "src/contexts/AppContext";
import createMilestoneTask from "src/firebase/createMilestoneTask";
import fetchMilestoneTasks from "src/firebase/fetchMilestoneTasks";
import updateMilestoneTaskCompletion from "src/firebase/updateMilestoneTaskCompletion";
import { MilestoneTask } from "src/types/MilestoneTask";
import { StudentPlanMilestone } from "src/types/StudentPlan";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";
import MilestoneCardHeader from "./MilestoneCardHeader";
import MilestoneTaskForm, { MilestoneTaskFormData } from "./MilestoneTaskForm";
import MilestoneTaskItem from "./MilestoneTaskItem";

type Props = {
  milestone: StudentPlanMilestone;
  handleCompletion?: () => void;
  handleEdit?: () => void;
  handleDeletion?: () => void;
  minified?: boolean;
};

export default function MilestoneCard({
  milestone,
  handleCompletion,
  handleEdit,
  handleDeletion,
  minified,
}: Props) {
  const { t } = useTranslation();
  const { clients } = useAppContext();
  const errorHandler = useErrorHandler();
  const [tasks, setTasks] = useState<MilestoneTask[]>();
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

  // load tasks
  useEffect(() => {
    fetchMilestoneTasks(
      {
        milestoneId: milestone.uid,
        userId: milestone.userId,
      },
      clients
    )
      .then(setTasks)
      .catch(errorHandler);
  }, [clients, errorHandler, milestone.uid, milestone.userId]);

  const handleCheck = (oldTask: MilestoneTask) => {
    updateMilestoneTaskCompletion(
      {
        task: oldTask,
        completed: !oldTask.completedAt,
      },
      clients
    )
      .then((updatedTask) => {
        setTasks((prev) => {
          const updated = replaceItem(
            prev || [],
            oldTask,
            updatedTask,
            (item) => item.uid === oldTask.uid
          );

          return [...updated];
        });
      })
      .catch(errorHandler);
  };

  const handleTaskCreation = (data: MilestoneTaskFormData) => {
    createMilestoneTask({ ...data, milestone }, clients)
      .then((task) => setTasks((prev) => (prev ? [...prev, task] : [task])))
      .catch(errorHandler);

    setIsCreateTaskModalOpen(false);
  };

  if (minified) {
    return (
      <Card>
        <CardContent>
          <MilestoneCardHeader tasks={tasks} milestone={milestone} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <MilestoneCardHeader tasks={tasks} milestone={milestone} />

        {tasks && (
          <Stack spacing={1} mt={2}>
            {tasks.map((task) => (
              <Box key={task.uid}>
                <MilestoneTaskItem
                  task={task}
                  onCheck={() => handleCheck(task)}
                />
              </Box>
            ))}
          </Stack>
        )}
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
            marginTop: 2,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2),
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          {handleCompletion &&
            (milestone.completedAt ? (
              <Stack direction="row" alignItems="center">
                <Checkbox
                  onClick={handleCompletion}
                  checked={!!milestone.completedAt}
                  color="success"
                  size="small"
                />
                <Typography variant="body2" color="text.secondary">
                  {t("Completed 🎉")}
                </Typography>
              </Stack>
            ) : (
              <Button variant="contained" onClick={handleCompletion}>
                {t("Mark as complete")}
              </Button>
            ))}
        </Box>
      </CardContent>
      <CardActions>
        {handleEdit && (
          <Button onClick={handleEdit} startIcon={<Edit />}>
            {t("Edit")}
          </Button>
        )}
        <Button
          onClick={() => setIsCreateTaskModalOpen(true)}
          startIcon={<AddOutlined />}
        >
          {t("Add Task")}
        </Button>
        {handleDeletion && (
          <Button onClick={handleDeletion} startIcon={<Delete />}>
            {t("Delete")}
          </Button>
        )}
      </CardActions>
      <Modal
        open={isCreateTaskModalOpen}
        onClose={() => setIsCreateTaskModalOpen(false)}
      >
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <MilestoneTaskForm onSubmit={handleTaskCreation} />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Card>
  );
}
