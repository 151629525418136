import { track } from "@amplitude/analytics-browser";

// eslint-disable-next-line import/prefer-default-export
export function amplitudeClickHandler(
  eventName: string,
  onClick: () => void,
  eventProperties: Record<string, string | number> = {}
) {
  return () => {
    track(eventName, eventProperties);
    onClick();
  };
}
