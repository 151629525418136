import { AddOutlined, NoteAdd } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyBoxIcon } from "src/assets/EmptyBox.svg";
import Button from "src/components/Button";
import ModalContainer from "src/components/ModalContainer";
import { ListSkeleton } from "src/components/Skeleton/closet";
import { useAppContext } from "src/contexts/AppContext";
import { useSession } from "src/contexts/AuthContext";
import createReference from "src/firebase/createReference";
import fetchReferences from "src/firebase/fetchReferences";
import updateReference from "src/firebase/updateReference";
import { SEED_REFERENCE_DATA } from "src/seed";
import { StudentReference } from "src/types/StudentReference";
import { UserAccount } from "src/types/User";
import replaceItem from "src/utils/replaceItem";
import useErrorHandler from "src/utils/useErrorHandler";
import { MIN_NUMBER_REFERENCES } from "./OnboardingScreen";
import ProgressIndicator from "./ProgressIndicator";
import ReferenceCard from "./ReferenceCard";
import ReferenceForm, { ReferenceFormData } from "./ReferenceForm";

type Props = {
  // only used for admin
  client: UserAccount;
  onChange?: (item: StudentReference, totalItems: number) => void;
  onLoad?: (items: StudentReference[]) => void;
};

export default function StudentReferenceSection({
  client,
  onChange,
  onLoad,
}: Props): JSX.Element {
  const session = useSession();
  const [references, setReferences] = useState<StudentReference[]>();
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [selected, setSelected] = useState<StudentReference>();
  const theme = useTheme();
  const { clients } = useAppContext();

  useEffect(() => {
    fetchReferences(client?.uid, clients)
      .then((references) => {
        setReferences(references);
        if (onLoad) onLoad(references);
      })
      .catch(errorHandler);
  }, [client, session, errorHandler, onLoad, clients]);

  const handleSubmit = async (data: ReferenceFormData) => {
    if (!references) throw new Error("Type narrowing");
    if (isCreating) {
      await createReference({ user: client, ...data }, clients)
        .then((reference) => {
          setReferences((prev) => [...(prev || []), reference]);
          setIsCreating(false);
          if (onChange) onChange(reference, references.length + 1);
        })
        .catch(errorHandler);
    }
    if (selected) {
      await updateReference(
        {
          user: client,
          payload: {
            ...selected,
            ...data,
          },
        },
        clients
      )
        .then((updatedReference) => {
          setReferences((prev) => {
            const updated = replaceItem(
              prev || [],
              selected,
              updatedReference,
              (item) => item.uid === updatedReference.uid
            );

            return [...updated];
          });
          setSelected(undefined);
        })
        .catch(errorHandler);
    }
  };

  const handleClose = () => {
    setIsCreating(false);
    setSelected(undefined);
  };

  const editingData: ReferenceFormData | undefined = useMemo(() => {
    if (!selected) return undefined;
    const data: ReferenceFormData = {
      ...selected,
      verified: !!selected.verifiedAt,
      subscribed: !!selected.subscribedAt,
    };
    return data;
  }, [selected]);

  if (!references)
    return (
      <Card>
        <CardContent>
          <ListSkeleton />
        </CardContent>
      </Card>
    );

  return (
    <Box overflow="auto">
      <Stack direction="row" alignItems="center" gap={1} marginBottom={2}>
        <ProgressIndicator
          progress={references.length}
          total={MIN_NUMBER_REFERENCES}
          label={t("references added")}
        />
      </Stack>

      {references.length ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={4}
          >
            <Typography>{t("Support network")}</Typography>
            <Button
              onClick={() => setIsCreating(true)}
              variant={
                references.length < MIN_NUMBER_REFERENCES
                  ? "contained"
                  : "outlined"
              }
              startIcon={<AddOutlined />}
            >
              {t("Add Reference")}
            </Button>
          </Stack>

          <Stack gap={2}>
            {references.map((reference) => (
              <Box key={reference.uid}>
                <ReferenceCard
                  onEdit={() => setSelected(reference)}
                  reference={reference}
                />
              </Box>
            ))}
          </Stack>
        </>
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Stack width="100%" alignItems="center" gap={theme.spacing(2)}>
              <EmptyBoxIcon width={200} />
              <Typography>{t("No references have been added yet.")}</Typography>
              <Button
                variant="contained"
                onClick={() => setIsCreating(true)}
                startIcon={<NoteAdd />}
              >
                {t("Add first reference")}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Modal open={isCreating || !!editingData} onClose={handleClose}>
        <div>
          <ModalContainer>
            <Card>
              <CardContent>
                <ReferenceForm
                  onSubmit={handleSubmit}
                  defaultValues={
                    editingData ||
                    (process.env.NODE_ENV === "development"
                      ? SEED_REFERENCE_DATA
                      : undefined)
                  }
                />
              </CardContent>
            </Card>
          </ModalContainer>
        </div>
      </Modal>
    </Box>
  );
}
