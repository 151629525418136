import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid, InputAdornment, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButton from "src/components/Button/SubmitButton";
import SelectInput from "src/components/Card/SelectInput";
import NumberInput from "src/components/Input/NumberInput";
import YesNoToggleInput from "src/components/Input/YesNoToggleInput";
import isBoolean from "src/utils/isBoolean";
import LABEL_MAP from "../ClientScreen/BackgroundTab/OnboardingSurveyLabels";
import SocialBenefits from "./SocialBenefits.enum";

export type SocialServicesData = {
  benefits: string[];
  householdIndividualsNumber?: number;
  totalIncomeLastSixMonths?: number;
  numberDependents: number;
  isCurrentOnChildSupport?: boolean;
  hasToPayChildSupport?: boolean;
};

type Props = {
  defaultValues: Partial<SocialServicesData>;
  onBack: () => void;
  onSubmit: (data: SocialServicesData) => void | Promise<void>;
};

export default function SocialServicesForm({
  defaultValues,
  onSubmit,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<SocialServicesData>({
    mode: "onTouched",
    defaultValues,
  });

  const hasToPayChildSupport = watch("hasToPayChildSupport");

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Stack spacing={2} marginBottom={6}>
        <SelectInput
          name="benefits"
          label={t("Tap to select")}
          prompt={LABEL_MAP.benefits}
          control={control}
          helperText={t("Leave blank if none")}
          multiple
          items={Object.entries(SocialBenefits).map((entry) => ({
            value: entry[0],
            name: entry[1],
          }))}
        />
        <NumberInput
          control={control}
          name="householdIndividualsNumber"
          prompt={LABEL_MAP.householdIndividualsNumber}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <NumberInput
          control={control}
          name="numberDependents"
          prompt={LABEL_MAP.numberDependents}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <NumberInput
          control={control}
          name="totalIncomeLastSixMonths"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          prompt={LABEL_MAP.totalIncomeLastSixMonths}
          helperText={t(
            "We know this can feel too personal, but it's important for us to know because some scholarships are only available for people under a certain income level. We'll handle your details with care and might need to verify later on!"
          )}
          rules={{
            required: {
              value: true,
              message: "Field is required",
            },
            min: 0,
          }}
        />
        <YesNoToggleInput
          name="hasToPayChildSupport"
          prompt={LABEL_MAP.hasToPayChildSupport}
          control={control}
          rules={{
            validate: (value) =>
              isBoolean(value) || t("This field is required"),
          }}
        />

        {hasToPayChildSupport && (
          <YesNoToggleInput
            name="isCurrentOnChildSupport"
            prompt={LABEL_MAP.isCurrentOnChildSupport}
            control={control}
            rules={{
              validate: (value) =>
                isBoolean(value) || t("This field is required"),
            }}
          />
        )}
      </Stack>
      <Grid container justifyContent="space-between">
        <Button onClick={onBack}>
          <ArrowBackIcon />
          &nbsp;
          {t("Back")}
        </Button>
        <SubmitButton loading={isSubmitting}>{t("Next")}</SubmitButton>
      </Grid>
    </form>
  );
}
