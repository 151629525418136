import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ScreenTitle } from "src/components/Typography";
import { useAppContext } from "src/contexts/AppContext";
import fetchBackground from "src/firebase/fetchBackgroundProfile";
import upsertEligibilityBackground from "src/firebase/upsertEligibilityBackground";
import { useCurrentUser } from "src/SessionBoundary";
import transformDateFields from "src/utils/transformDateFields";
import useErrorHandler from "src/utils/useErrorHandler";
import AppSkeleton from "../AppSkeleton";
import {
  BackgroundFormData,
  EligibilityFormData,
} from "../BackgroundSurveyScreen/BackgroundSurveyView";
import DriverLicenseForm from "../BackgroundSurveyScreen/DriverLicenseForm";
import SocialSecurityForm from "../BackgroundSurveyScreen/SocialSecurityForm";
import SuccessScreenModal from "../SucessScreenModal";
import Signature from "./Signature.svg";

export type EligibilityForm = Pick<
  BackgroundFormData,
  | "ssn"
  | "driverLicenseNumber"
  | "driverLicenseIssueDate"
  | "driverLicenseExpirationDate"
  | "driverLicenseState"
>;
type FormStep = "income" | "ssn" | "driver_license";

const stepLabels: Record<FormStep, string> = {
  ssn: "Social Security Number",
  driver_license: "Driver's License",
  income: "Income",
};

const stepNames: FormStep[] = ["ssn", "driver_license"];

const getStepIndex = (stepName: FormStep): number =>
  stepNames.indexOf(stepName);

export default function EligibilityScreen() {
  const { t } = useTranslation();
  const [step, setStep] = useState<FormStep>("ssn");
  const [finished, setFinished] = useState(false);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const errorHandler = useErrorHandler();
  const [eligibilityData, setEligibilityData] =
    useState<Partial<EligibilityFormData>>();
  const { clients } = useAppContext();

  useEffect(() => {
    fetchBackground(user.uid, clients)
      .then((background) => {
        if (background) {
          const transformed = transformDateFields(background);
          setEligibilityData({
            hasSocialSecurityNumber: transformed.hasSocialSecurityNumber,
            hasSocialSecurityCardAvailable:
              transformed.hasSocialSecurityCardAvailable,
            ssn: transformed.ssn,
            // driver license
            driverLicenseNumber: transformed.driverLicenseNumber,
            driverLicenseState: transformed.driverLicenseState,
            driverLicenseIssueDate: transformed.driverLicenseIssueDate,
            driverLicenseExpirationDate:
              transformed.driverLicenseExpirationDate,
          });
        }
      })
      .catch(errorHandler);
  }, [clients, errorHandler, user.uid]);

  const onSubmit = async (input: EligibilityFormData) => {
    await upsertEligibilityBackground({ user, ...input }, clients)
      .then(() => setFinished(true))
      .catch(errorHandler);
  };

  const handleNavigation = () => {
    navigate("/onboarding/references");
  };

  if (!eligibilityData) return <AppSkeleton />;

  if (!finished) {
    return (
      <>
        <ScreenTitle>{stepLabels[step]}</ScreenTitle>

        <Box width="100%" marginTop={2}>
          <LinearProgress
            value={(getStepIndex(step) * 100) / stepNames.length}
            variant="determinate"
          />
          <Stack direction="row">
            <Typography variant="body2" color="text.secondary">
              {`${Math.round((getStepIndex(step) * 100) / stepNames.length)}%`}
            </Typography>
          </Stack>
        </Box>
        {step === "ssn" && (
          <>
            <Box marginTop={2}>
              <Typography variant="body2" color="text.secondary">
                {t(
                  "To help you find the right scholarships and run your background to determine eligibility, we need your Social Security information."
                )}
              </Typography>
            </Box>
            <SocialSecurityForm
              defaultValues={eligibilityData}
              onSubmit={(data) => {
                setEligibilityData((prev) => ({ ...prev, ...data }));
                setStep("driver_license");
              }}
            />
          </>
        )}
        {step === "driver_license" && (
          <DriverLicenseForm
            defaultValues={eligibilityData}
            onBack={() => setStep("ssn")}
            onSubmit={(data) => {
              setEligibilityData({ ...eligibilityData, ...data });
              onSubmit({
                ...eligibilityData,
                ...data,
              } as EligibilityFormData);
            }}
          />
        )}
      </>
    );
  }

  return (
    <SuccessScreenModal
      open={finished}
      illustration={Signature}
      illustrationWidth={200}
      title={t("Thanks for sharing!")}
      content={t(
        "We now have all the information needed to determine your eligibility! Up next, you will build your success plan in the program."
      )}
      okButtonText={t(`Let's keep going`)}
      onOkButtonClick={handleNavigation}
    />
  );
}
